/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader'; // eslint-disable-next-line
import avatar_img from '../../../assets/img/team/no_gl_seal_light.png'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import EditLodgeNameModal from './EditLodgeNameModal'; // eslint-disable-next-line
import LodgeContentModal from './LodgeContentModal'; // eslint-disable-next-line
import LodgeModalLogoSection from './LodgeModalLogoSection'; // eslint-disable-next-line
import LodgeHistoryModal from './LodgeHistoryModal'; // eslint-disable-next-line

const LodgeModalNameSection = props => {
	// eslint-disable-next-line
	const { lodgeData, pageLanguageContent, updateLodgeModal, lodgeImages } = props;
	const [showHideNameModal, setShowHideNameModal] = useState(false);
	const [id, setId] = useState('');
	const [showHideContentModal, setShowHideContentModal] = useState(false);
	const [contentType, setContentType] = useState('');
	const [headerLabel, setHeaderLabel] = useState('');
	const [showHideHistoryModal, setShowHideHistoryModal] = useState(false);
	const [historyText, setHistoryText] = useState('');

	/* show edit name lodge modal */
	const openNameLodgeModal = () => {
		setShowHideNameModal(true);
	};
	/* hide edit name lodge modal */
	const closeModal = () => {
		setShowHideNameModal(false);
	};

	/* open history lodge modal */
	const openHistoryLodgeModal = text => {
		setHistoryText(text);
		setShowHideHistoryModal(true);
	};
	/* hide history lodge modal */
	const closeHistoryModal = () => {
		setShowHideHistoryModal(false);
	};

	/* open lodge content modal */
	const openLodgeContentModal = (text, type) => {
		setId(lodgeData['id']);
		setHeaderLabel(text);
		setContentType(type);
		setShowHideContentModal(true);
	};
	/* hide lodge content modal */
	const closeLodgeContentModal = () => {
		setShowHideContentModal(false);
	};

	/* get format data */
	function getFormattedDate(date) {
		var formatDate = '';
		if (isNaN(date) === false) {
			// eslint-disable-next-line
			if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) {
				return formatDate;
			} else {
				const today = new Date(date);
				const options = { day: 'numeric', month: 'long', year: 'numeric' };
				formatDate = new Intl.DateTimeFormat('en-US', options).format(today);
			}
		} else {
			return date;
		}
		return formatDate;
	}
	return (
		<>
			<Row className='align-items-center border-bottom lodge-user-profile-box'>
				<Col lg={2}>
					<LodgeModalLogoSection
						lodgeData={lodgeData}
						pageLanguageContent={pageLanguageContent}
						updateLodgeModal={updateLodgeModal}
					/>
					{/*<div className="lodge-user-profile-box">
                   <div className="lodge-user-profile">
                     <img src ={avatar_img} alt="avatar"/>
                   </div>
                    <div className="lodge-user-btn">
                        <Badge color={'soft-info'}  className="mr-1" >
                          <i className="far fa-edit"></i>
                        </Badge>
                    </div>
               </div>*/}
				</Col>
				<Col lg={10}>
					<div className='d-flex lodge-top-head'>
						<h5>{lodgeData['name']}</h5>
						<div className='ml-auto'>
							<Badge color={'soft-info'} className='mr-1' onClick={() => openNameLodgeModal()}>
								<i className='far fa-edit'></i>
							</Badge>
						</div>
					</div>
					<Row>
						<Col lg={6} className='border-lg-right'>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD022'] ? pageLanguageContent['CLD022'] : ''}:</h6>
								</div>
								<div className='d-flex lodge-middle-box'>
									<span>{lodgeData['founded']?getFormattedDate(lodgeData['founded']):pageLanguageContent['CLD040']}</span>
								</div>
								<div className='ml-auto btns-ladge'>
									<Badge color={'soft-info'} className='mr-1'>
										<i className='far fa-edit' onClick={() => openLodgeContentModal('Edit Charter', 'charter')}></i>
									</Badge>
								</div>
							</div>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD024'] ? pageLanguageContent['CLD024'] : ''}:</h6>
									<div className='ladge-radio-btn'>
										<input type='radio' id='radio1' name={'btnradio-'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD026'] ? pageLanguageContent['CLD026'] : ''}</label>
										<input type='radio' id='radio1' name={'btnradio-'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD027'] ? pageLanguageContent['CLD027'] : ''}</label>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={6}>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD023'] ? pageLanguageContent['CLD023'] : ''}:</h6>
									<div className='ladge-radio-btn'>
										<input type='radio' id='radio1' name={'btnradio-status'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD122'] ? pageLanguageContent['CLD122'] : ''}</label>
										<input type='radio' id='radio1' name={'btnradio-status'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD123'] ? pageLanguageContent['CLD123'] : ''}</label>
									</div>
								</div>
								<div className='ml-auto btns-ladge'>
									<Badge color={'soft-info'} className='mr-1' onClick={() => openHistoryLodgeModal('status')}>
										<i className='fas fa-eye'></i>
									</Badge>
								</div>
							</div>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD025'] ? pageLanguageContent['CLD025'] : ''}:</h6>
									<div className='ladge-radio-btn'>
										<input type='radio' id='radio1' name={'btnradio-Type'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD026'] ? pageLanguageContent['CLD026'] : ''}</label>
										<input type='radio' id='radio1' name={'btnradio-Type'} className='mr-1 memebrAdmin' />{' '}
										<label htmlFor='radio1'>{pageLanguageContent['CLD027'] ? pageLanguageContent['CLD027'] : ''}</label>
									</div>
								</div>
							</div>
						</Col>
					</Row>


					<Row>
						<Col lg={6} className='border-lg-right'>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD158']?pageLanguageContent['CLD158']:''}:</h6>
								</div>
								<div className='d-flex lodge-middle-box'>
									<span>{lodgeData['print_name']?lodgeData['print_name']:pageLanguageContent['CLD040']}</span>
								</div>
								<div className='ml-auto btns-ladge'>
									<Badge color={'soft-info'} className='mr-1'>
										<i className='far fa-edit' onClick={() => openLodgeContentModal(pageLanguageContent['CLD158']?pageLanguageContent['CLD158']:'', 'print_name')}></i>
									</Badge>
								</div>
							</div>
						</Col>
						
					</Row>




				</Col>
			</Row>
			<EditLodgeNameModal
				showHideModal={showHideNameModal}
				closeModal={closeModal}
				lodgeData={lodgeData}
				pageLanguageContent={pageLanguageContent}
				updateLodgeModal={updateLodgeModal}
			/>

			<LodgeContentModal
				showHide={showHideContentModal}
				closeModal={closeLodgeContentModal}
				id={id}
				headerLabel={headerLabel}
				contentType={contentType}
				lodgeData={lodgeData}
				pageLanguageContent={pageLanguageContent}
				updateLodgeModal={updateLodgeModal}
			/>

			<LodgeHistoryModal
				lodgeData={lodgeData}
				pageLanguageContent={pageLanguageContent}
				id={id}
				showHideHistoryModal={showHideHistoryModal}
				closeHistoryModal={closeHistoryModal}
				updateLodgeModal={updateLodgeModal}
				historyText={historyText}
			/>
		</>
	);
};
export default LodgeModalNameSection;
