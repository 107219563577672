/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import FormGroupInput from '../../common/FormGroupInput';
// eslint-disable-next-line
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader';
import LanguageField from './fields/LanguageField';
import RiteField from './fields/RiteField';
import RitualField from './fields/RitualField';
import MeetingsField from './fields/MeetingsField';
import NotesField from './fields/NotesField';
import WebsiteField from './fields/WebsiteField';
import EmailField from './fields/EmailField';
import PhoneField from './fields/PhoneField';
import FacebookField from './fields/FacebookField';
import InstagramField from './fields/InstagramField';
import TwitterField from './fields/TwitterField';
import CharterField from './fields/CharterField';
import LinkedinField from './fields/LinkedinField';
import DistrictField from './fields/DistrictField';
import PrintNameField from './fields/PrintNameField';
import TiktokField from './fields/TiktokField';
import ThreadsField from './fields/ThreadsField';
import BlueskyField from './fields/BlueskyField';

const LodgeContentModal = props => {
	const { id, headerLabel, contentType, lodgeData, updateLodgeModal } = props;
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	useEffect(() => {
		if (props.showHide) {
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			if (checkData['CLD']) {
				setPageLanguageContent(checkData['CLD']);
			}
		}
		// eslint-disable-next-line
	}, [props.showHide]);

	return (
		<div>
			<Modal isOpen={props.showHide} toggle={props.closeModal} id='lodge_modal'>
				<ModalHeader toggle={props.closeModal}>{headerLabel}</ModalHeader>
				<ModalBody>
					<Form>
						{contentType && contentType === 'charter' && (
							<CharterField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'language' && (
							<LanguageField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'rite' && (
							<RiteField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'ritual' && (
							<RitualField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'meetings' && (
							<MeetingsField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'notes' && (
							<NotesField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'website' && (
							<WebsiteField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'email' && (
							<EmailField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'phone' && (
							<PhoneField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'facebook' && (
							<FacebookField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'instagram' && (
							<InstagramField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'twitter' && (
							<TwitterField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'linkedin' && (
							<LinkedinField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'district' && (
							<DistrictField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'print_name' && (
							<PrintNameField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'tiktok' && (
							<TiktokField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'threads' && (
							<ThreadsField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
						{contentType && contentType === 'bluesky' && (
							<BlueskyField
								lodgeData={lodgeData}
								id={id}
								closeModal={props.closeModal}
								updateLodgeModal={updateLodgeModal}
								pageLanguageContent={pageLanguageContent}
							/>
						)}
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default LodgeContentModal;
