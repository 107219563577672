/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost,getMarkerIcon } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader'; // eslint-disable-next-line
import map_img from '../../../assets/img/logos/map.jpg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import LodgeHistoryModal from './LodgeHistoryModal.js'; // eslint-disable-next-line
import LodgeAddressModal from './LodgeAddressModal'; // eslint-disable-next-line
import LodgeContentModal from './LodgeContentModal'; // eslint-disable-next-line
import 'leaflet/dist/leaflet.css'; // eslint-disable-next-line
import { Map, Marker, Popup, Circle, TileLayer } from 'react-leaflet';
import 'leaflet.tilelayer.colorfilter'; // eslint-disable-next-line
import L from 'leaflet'; // eslint-disable-next-line
import 'react-leaflet-fullscreen/dist/styles.css'; // eslint-disable-next-line
const filter = ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];
const LodgeModalContentSection = props => {
	const { lodgeData, pageLanguageContent, updateLodgeModal } = props;
	const [showHideHistoryModal, setShowHideHistoryModal] = useState(false);
	const [showHideAddressModal, setShowHideAddressModal] = useState(false);
	const [showHideContentModal, setShowHideContentModal] = useState(false);
	const [contentType, setContentType] = useState('');
	const [headerLabel, setHeaderLabel] = useState('');
	const [historyText, setHistoryText] = useState('');
	const [markerIcon,setMarkerIcon] = useState('')
	const [id, setId] = useState('');
	const [lat, setLat] = useState();
	const [long, setLong] = useState();
	let map = useRef(null); // eslint-disable-next-line
	const egMap = useRef();		
	useEffect(() => {
		if (lodgeData['id']) {
			var m_icon = getMarkerIcon(lodgeData?.id,lodgeData?.affiliation);
			setMarkerIcon(m_icon)
			setId(lodgeData['id']);
			if (lodgeData['location']) {
				setLat(lodgeData['location']['latitude']);
				setLong(lodgeData['location']['longitude']);				
			}
		}
		// eslint-disable-next-line
	}, [lodgeData['id']]);
	
	let DefaultIcon = L.icon({
		iconSize: [25, 41],
		iconAnchor: [10, 41],
		popupAnchor: [2, -40],
		iconUrl: markerIcon
	});

	/* function to show history modal */
	const openLodgeModal = text => {
		setHistoryText(text);
		setShowHideHistoryModal(true);
	};
	/* function to hide history modal */
	const closeHistoryModal = () => {
		setShowHideHistoryModal(false);
	};

	//openLodgeAddressModal
	/* function to show lodge address modal */
	const openLodgeAddressModal = () => {
		setId(lodgeData['id']);
		setShowHideAddressModal(true);
	};
	/* function to hide lodge address modal */
	const closeLodgeAddressModal = () => {
		setShowHideAddressModal(false);
	};

	/* function to show lodge content modal */

	const openLodgeContentModal = (text, type) => {
		setId(lodgeData['id']);
		setHeaderLabel(text);
		setContentType(type);
		setShowHideContentModal(true);
	};

	/* function to hide lodge content modal */
	const closeLodgeContentModal = () => {
		setShowHideContentModal(false);
	};
	return (
		<>
			<Row className='lodge-middle-content-area border-bottom'>
				<Col lg={8}>
					<div className='lodge-middle-box'>
						<h6>{pageLanguageContent['CLD028'] ? pageLanguageContent['CLD028'] : ''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>
								{lodgeData['address'] &&
									lodgeData['address'].length > 0 &&
									lodgeData['address'].map((item, index) => <span key={index}> {item} </span>)}
							</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1' onClick={() => openLodgeAddressModal()}>
									<i className='far fa-edit'></i>
								</Badge>
								{lodgeData['address'] && lodgeData['address'].length > 0 ? (
									<Badge color={'soft-info'} className='mr-1' onClick={() => openLodgeModal('address')}>
										<i className='fas fa-eye'></i>
									</Badge>
								) : (
									<Badge color={'soft-secondary'} className='mr-1'>
										<i className='fas fa-eye'></i>
									</Badge>
								)}
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD157']?pageLanguageContent['CLD157']:''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['district']?lodgeData['district']:pageLanguageContent['CLD040']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(
												pageLanguageContent['CLD157']?pageLanguageContent['CLD157']:'',
												'district'
											)
										}
									></i>
								</Badge>
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD029'] ? pageLanguageContent['CLD029'] : ''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['language']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(
												pageLanguageContent['CLD134'] ? pageLanguageContent['CLD134'] : '',
												'language'
											)
										}
									></i>
								</Badge>
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD030'] ? pageLanguageContent['CLD030'] : ''}: </h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['rite']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(pageLanguageContent['CLD135'] ? pageLanguageContent['CLD135'] : '', 'rite')
										}
									></i>
								</Badge>
								{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('rite')}>
                                      <i className="fas fa-eye"></i>
                                    </Badge>*/}
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD031'] ? pageLanguageContent['CLD031'] : ''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['ritual']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(
												pageLanguageContent['CLD136'] ? pageLanguageContent['CLD136'] : '',
												'ritual'
											)
										}
									></i>
								</Badge>
								{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('ritual')}>
                                    <i className="fas fa-eye"></i>
                                  </Badge>*/}
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD032'] ? pageLanguageContent['CLD032'] : ''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['meetings']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(
												pageLanguageContent['CLD137'] ? pageLanguageContent['CLD137'] : '',
												'meetings'
											)
										}
									></i>
								</Badge>
								{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('meeting')}>
                                    <i className="fas fa-eye"></i>
                                  </Badge>*/}
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
					<div className='d-flex lodge-middle-box'>
						<h6>{pageLanguageContent['CLD033'] ? pageLanguageContent['CLD033'] : ''}:</h6>
						<div className='d-flex lodge-cnt-icon'>
							<p>{lodgeData['notes']}</p>
							<div className='ml-auto btns-ladge'>
								<Badge color={'soft-info'} className='mr-1'>
									<i
										className='far fa-edit'
										onClick={() =>
											openLodgeContentModal(pageLanguageContent['CLD138'] ? pageLanguageContent['CLD138'] : '', 'notes')
										}
									></i>
								</Badge>
								{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('notes')}>
                                <i className="fas fa-eye"></i>
                              </Badge>*/}
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							</div>
						</div>
					</div>
				</Col>
				<Col lg={4}>
					<div className='lodge-middle-map'>
						{lat && long ? (
							<Map center={[lat, long]} zoom={17} style={{ minHeight: 250 }} ref={map}>
								<TileLayer url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
								<Marker position={[lat, long]} draggable={false} icon={DefaultIcon}></Marker>
							</Map>
						) : (
							<img src={map_img} alt='map' />
						)}
					</div>
				</Col>
			</Row>
			<LodgeHistoryModal
				lodgeData={lodgeData}
				pageLanguageContent={pageLanguageContent}
				id={id}
				showHideHistoryModal={showHideHistoryModal}
				closeHistoryModal={closeHistoryModal}
				updateLodgeModal={updateLodgeModal}
				historyText={historyText}
			/>

			<LodgeAddressModal
				lodgeData={lodgeData}
				pageLanguageContent={pageLanguageContent}
				id={id}
				showHide={showHideAddressModal}
				closeModal={closeLodgeAddressModal}
				updateLodgeModal={updateLodgeModal}
			/>

			<LodgeContentModal
				showHide={showHideContentModal}
				closeModal={closeLodgeContentModal}
				id={id}
				headerLabel={headerLabel}
				contentType={contentType}
				lodgeData={lodgeData}
				updateLodgeModal={updateLodgeModal}
				pageLanguageContent={pageLanguageContent}
			/>
		</>
	);
};
export default LodgeModalContentSection;
