/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import LodgeModalNameSection from './lodgemodal/LodgeModalNameSection'; // eslint-disable-next-line
import LodgeModalContentSection from './lodgemodal/LodgeModalContentSection'; // eslint-disable-next-line
import LodgeContactSection from './lodgemodal/LodgeContactSection'; // eslint-disable-next-line
import LodgeModalImageSection from './lodgemodal/LodgeModalImageSection'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/no_gl_seal_light.png'; // eslint-disable-next-line
import img_1 from '../../assets/img/lodges/img_1.png'; // eslint-disable-next-line
import img_2 from '../../assets/img/lodges/img_2.png'; // eslint-disable-next-line
import img_3 from '../../assets/img/lodges/img_3.png'; // eslint-disable-next-line
import img_4 from '../../assets/img/lodges/img_4.png'; // eslint-disable-next-line
import map_img from '../../assets/img/logos/map.jpg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const LodgeModal = props => {
	// eslint-disable-next-line
	const [lodgeData, setLodgeData] = useState([]); // eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(false); // eslint-disable-next-line
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	const [lodgeImages, setLodgeImages] = useState([]);
	useEffect(() => {
		/* Showing the loaded data if any*/
		if (props.id && props.showHide) {
			getData(props.id);
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			if (checkData['CLD']) {
				setPageLanguageContent(checkData['CLD']);
			}
		}
		return () => {
			//setLodgeData('');
		};
		// eslint-disable-next-line
	}, [props]);

	/* API to get the initial data for organizational modal */
	function getData(id) {
		setShowHideLoader(true);
		copiriPortalRequest('/organizations/' + id + '?format=details', config.app_version, 'get')
			.then(response => {
				//setShowHideLoader(false);
				getOrgImage(id);
				if (response?.data?.result) {
					setLodgeData(response?.data?.result);
				} else {
					setLodgeData([]);
				}
			})
			.catch(error => {
				setShowHideLoader(false);
			});
	}

	/* function to get the organization images */
	function getOrgImage(id) {
		setLodgeImages([]);
		setShowHideLoader(true);
		copiriPortalRequest('/organizations/' + id + '/images', config.app_version, 'get')
			.then(response => {
				setShowHideLoader(false);
				if (response.data.carousel.length > 0) {
					setLodgeImages(response.data.carousel);
				}
			})
			.catch(error => {
				setShowHideLoader(false);
			});
	}

	/* function to refresh the organization modal when there is any update on the modals*/
	function updateLodgeModal(type = '', value = '', social_icon = '', link) {
		if (type && type !== '') {
			if (type === 'social') {
				let socialObject = lodgeData['social'];				
				if (social_icon === 'facebook') {
					socialObject['facebook']= value;
					socialObject['facebook_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'instagram') {
					socialObject['instagram']= value;
					socialObject['instagram_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'twitter') {
					socialObject['twitter']= value;
					socialObject['twitter_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'linkedin') {
					socialObject['linkedin']= value;
					socialObject['linkedin_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'bluesky') {					
					socialObject['bluesky']= value;
					socialObject['bluesky_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'threads') {
					socialObject['threads']= value;
					socialObject['threads_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else if (social_icon === 'tiktok') {
					socialObject['tiktok']= value;
					socialObject['tiktok_link']= true;
					setLodgeData({ ...lodgeData, social: socialObject});
				} else {
				}
			} else {
				setLodgeData({ ...lodgeData, [type]: value });
			}
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick} size='xl' id='lodge_modal'>
				{showHideLoader ? (
					<CopiriLoader />
				) : (
					<>
						<ModalHeader toggle={props.onClick}></ModalHeader>
						<ModalBody>
							<LodgeModalNameSection
								lodgeData={lodgeData}
								pageLanguageContent={pageLanguageContent}
								updateLodgeModal={updateLodgeModal}
								lodgeImages={lodgeImages}
							/>
							<LodgeModalContentSection
								lodgeData={lodgeData}
								pageLanguageContent={pageLanguageContent}
								updateLodgeModal={updateLodgeModal}
							/>
							<LodgeContactSection
								lodgeData={lodgeData}
								pageLanguageContent={pageLanguageContent}
								updateLodgeModal={updateLodgeModal}
							/>
							<LodgeModalImageSection
								lodgeData={lodgeData}
								pageLanguageContent={pageLanguageContent}
								updateLodgeModal={updateLodgeModal}
								lodgeImages={lodgeImages}
							/>
						</ModalBody>
						<ModalFooter>
							<Button color='secondary' onClick={props.onClick}>
								{genPageData['GENCLS']?genPageData['GENCLS']:''}
							</Button>
						</ModalFooter>
					</>
				)}
			</Modal>
		</>
	);
};
export default LodgeModal;
